import React from "react"
import SEO from "../components/seo"

import "../scss/usecases.scss"

import Icon1 from "../assets/img/youpalGroup/useCases/Icon1.png"
import Icon2 from "../assets/img/youpalGroup/useCases/Icon2.png"
import Icon3 from "../assets/img/youpalGroup/useCases/Icon3.png"
import Icon4 from "../assets/img/youpalGroup/useCases/Icon4.png"
import RobotHero from "../assets/img/youpalGroup/robotHero.png"

const UseCasesPage = () => {
  return (
    <div>
        <SEO title="Use Cases" keywords={[`youpal`, `youpal group`]} />
        <section className="container usecaseSection">
            <div className="row useCaseContainer">
                <div className="col sideContentCol">
                    <div className="col useCase">
                        <h2>Use Cases</h2>
                        <p>
                            Youpal Group has been working in different industries with the ability to change the workflow and the life of multiple people. Here we are capable of facing any challenge and come up with the best possible solution. There are some of the most successful cases of different industries, yet yours can be the next to join. 
                        </p>
                    </div>
                </div>
                <div className="col heroCol">
                    <img className="sideImgHero sideImg" src={RobotHero} />
                </div>
            </div>
        </section>

        <section className="container usecaseSection buggie">
            <div className="col">
                <div className="col useCaseAlign">
                    <img src={Icon1} />
                    <h3>X-shore</h3>
                    <p>
                    X-Shore is a 100% electric boat company, meaning that the boats are designed from the bottom to the top to be fully electric. Moreover, they have no legacy, which enables them to build the boats according to their wishes and vision, using all the technologies that have come out within the past decade. 
                    </p>
                </div>
                <div className="col useCaseAlign">
                    <img src={Icon2} />
                    <h3>Empowering women in business</h3>
                    <p>
                        Are you a female entrepreneur? <br />
                        It's difficult to start and run your own business. It requires that you are the CEO, CMO, CTO, CFO all at once. <br />
                        IWG is an International Women´s Group that helps women to connect with companies, other entrepreneurs, business coaches, investors and much more.
                    </p>
                </div>
                <div className="col useCaseAlign">
                    <img src={Icon3} />
                    <h3>Banking</h3>
                    <p>
                        The bank decided to do a digital first approach to ensure a relevant, engaging and omni-channel experience for the modern customer. This meant creating contextual anywhere, anytime personal and corporate banking that delivered a personalised experience across digital touch-points. The approach to this project was to revamp the design of the online and mobile interfaces, develop smooth digital customer onboarding, build a corporate banking ecosystem, develop instant money transfers. The next phase was to leverage the opportunities of open banking, by benefitting from the omni-channel experience, creating APIs and activating services with third party partnerships.
                    </p>
                </div>
                <div className="col useCaseAlign">
                    <img src={Icon4} />
                    <h3>Medicine</h3>
                    <p>
                        Doktorsjouren is a human resources healthcare company that considers it an important part of its responsibility to reinvest in health care. They do this by developing, in parallel with staffing, long-term solutions to reduce recruitment costs and facilitate the hiring of permanent staff. Doktorsjouren website has been completely renewed and modernised to allow them to grow their business and scale without any technical limitation.
                    </p>
                </div>
            </div>
        </section>
    </div>
  )
}

export default UseCasesPage
